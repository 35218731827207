import React from 'react';
import collapseImg from './images/collapse.png';
import styles from './styles.module.scss';

const Placeholder = () => (
  <div className={styles.container}>
    <div className={styles.header}>Jobandtalent’s back-office</div>
    <div className={styles.content}>
      <div className={styles.welcome}>
        <div className={styles.title}>Welcome to Jobandtalent’s back-office</div>
        <div className={styles.subtitle}>
          You can find all the tools you need on the left side menu, once you&apos;re done you can
          collapse it to get more real state on your screen.
        </div>
        <img alt="" className={styles.image} src={collapseImg} />
      </div>
    </div>
  </div>
);

export default Placeholder;
