import React, { memo } from 'react';
import spinWhiteIcon from './images/spinWhite.png';
import styles from './styles.module.scss';

const Spinner = () => (
  <div className={styles.container}>
    <img alt="" src={spinWhiteIcon} />
  </div>
);

export default memo(Spinner);
