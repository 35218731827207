import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AUTH_CALLBACK_PATH, HOME_PATH } from 'router/paths';
import UserContext from 'context/user';

const OAUTH_PATH = `${process.env.REACT_APP_AUTH_PUBLIC_URL}/oauth/authorize?client_id=${process.env.REACT_APP_AUTH_SERVICES_CLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_BACKOFFICE_URL}${AUTH_CALLBACK_PATH}&scope=permissions`;

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      const { from } = location.state || { from: { pathname: HOME_PATH } };

      history.replace(from);
    } else {
      localStorage.setItem('loginFrom', location.state.from.pathname);
      if (window.location.search !== '') {
        localStorage.setItem('loginQueryParams', window.location.search);
      }

      window.location.href = OAUTH_PATH;
    }
  }, [history, location.state, user]);

  return null;
};

export default Login;
