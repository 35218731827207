import React, { useContext } from 'react';
import { Menu } from '@jobandtalent/backoffice-navigation';
import StoreContext from 'context/store';
import UserContext from 'context/user';
import Project from 'components/Project';
import Placeholder from './Placeholder';
import '@jobandtalent/backoffice-navigation/dist/styles.css';
import styles from './styles.module.scss';

const Home = () => {
  const { currentProjectUrl, section, updateProjectUrl } = useContext(StoreContext);
  const { user } = useContext(UserContext);

  return (
    <div className={styles.container}>
      <Menu
        onClickCategory={(value) => updateProjectUrl(value)}
        selectedSectionId={section ? section.id : undefined}
        user={{
          avatar: user.picture_url,
          name: user.name,
          permissions: user.permissions,
        }}
      />
      {currentProjectUrl ? <Project className={styles.project} /> : <Placeholder />}
    </div>
  );
};

export default Home;
