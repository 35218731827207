export default [
  {
    id: 'candidates',
    project: 'candidates',
    url: `${process.env.REACT_APP_CANDIDATES_URL}/admin`,
  },
  {
    id: 'import_candidates',
    project: 'candidates',
    url: `${process.env.REACT_APP_CANDIDATES_URL}/admin/candidates/imports/new`,
  },
  {
    id: 'companies',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin`,
  },
  {
    id: 'company_groups',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/company_groups`,
  },
  {
    id: 'job_openings',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/job_openings`,
  },
  {
    id: 'job_functions',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/job_functions`,
  },
  {
    id: 'industries',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/industries`,
  },
  {
    id: 'contracts',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/transactions/hirings`,
  },
  {
    id: 'contracts_tasks',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/transactions/hiring_tasks/hiring_tasks`,
  },
  {
    id: 'payslips',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/transactions/work_periods`,
  },
  {
    id: 'payments',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/transactions/payments`,
  },
  {
    id: 'seizures',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/transactions/seizures`,
  },
  {
    id: 'hmrc_submissions',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/transactions/enrolment_gb/hmrc/submissions`,
  },
  {
    id: 'document_signature_template',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/additional_documents/configurations`,
  },
  {
    id: 'document_signature_send_new',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/bulk_operations/additional_documents/new`,
  },
  {
    id: 'document_signature_history',
    project: 'companies',
    url: `${process.env.REACT_APP_COMPANIES_URL}/admin/additional_documents/document_processes`,
  },
];
