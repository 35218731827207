import { useLocation } from 'react-router-dom';

const useUrlParams = (keys) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return keys.reduce(
    (carry, key) => ({
      ...carry,
      [key]: params.get(key),
    }),
    {}
  );
};

export default useUrlParams;
