import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import UserContext from 'context/user';

const AuthCallback = () => {
  const location = useLocation();
  const history = useHistory();
  const { user, fetchUser } = useContext(UserContext);
  const params = new URLSearchParams(location.hash.replace('#', '?'));
  const accessToken = params.get('access_token');

  useEffect(() => {
    fetchUser(accessToken);
  }, [fetchUser, accessToken]);

  useEffect(() => {
    if (user) {
      const search = localStorage.getItem('loginQueryParams');
      const pathname = localStorage.getItem('loginFrom');
      localStorage.removeItem('loginQueryParams');
      localStorage.removeItem('loginFrom');

      history.replace({ pathname, search });
    }
  }, [history, user]);

  return null;
};

export default AuthCallback;
