/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import domains from 'data/domains';
import sections from 'data/sections';
import useUrlParams from 'hooks/useUrlParams';

const StoreContext = React.createContext();

export const StoreProvider = ({ children }) => {
  const history = useHistory();
  const urlParams = useUrlParams(['state']);
  const state = urlParams.state ? JSON.parse(window.atob(urlParams.state)) : null;
  const [currentProjectUrl, setCurrentProjectUrl] = useState(undefined);
  const [section, setSection] = useState(undefined);

  const handlePostMessage = (event) => {
    const { type, value } = event.data;

    switch (type) {
      case 'location':
        setCurrentProjectUrl(value);
        break;
      default:
        break;
    }
  };
  const updateProjectUrl = (sectionId) => {
    const sectionData = sections.find((item) => item.id === sectionId);

    setSection(sectionData);
    setCurrentProjectUrl(sectionData.url);
  };

  useEffect(() => {
    if (currentProjectUrl) {
      history.replace({
        pathname: currentProjectUrl
          .replace(domains[section.project], '')
          .replace('#', '%23')
          .replace('?', '%3F')
          .replace(/&/g, '%26'),
        search: `?state=${window.btoa(JSON.stringify({ sectionId: section.id }))}`,
      });
    }
  }, [currentProjectUrl]);

  useEffect(() => {
    if (state) {
      const stateSection = sections.find((item) => item.id === state.sectionId);

      setSection(stateSection);
      setCurrentProjectUrl(domains[stateSection.project] + window.location.pathname);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  return (
    <StoreContext.Provider
      value={{
        currentProjectUrl,
        section,
        updateProjectUrl,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StoreContext;
