/* eslint-disable jsx-a11y/iframe-has-title */
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import StoreContext from 'context/store';
import Spinner from 'components/Spinner';
import styles from './styles.module.scss';

const Project = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentProjectUrl, section } = useContext(StoreContext);

  const applyProjectChanges = () => {
    switch (section.project) {
      case 'candidates':
        window.frames.project.postMessage({ type: 'hideCandidatesHeader' }, '*');
        break;
      case 'companies':
        window.frames.project.postMessage({ type: 'hideCompaniesSidebar' }, '*');
        break;
      case 'farming':
        window.frames.project.postMessage({ type: 'hideFarmingHeader' }, '*');
        break;
      case 'scheduler':
        window.frames.project.postMessage({ type: 'hideSchedulerHeader' }, '*');
        break;
      case 'workforce':
        window.frames.project.postMessage({ type: 'hideWorkforceHeader' }, '*');
        break;
      default:
        break;
    }
  };

  const onLoadIframe = () => {
    applyProjectChanges();
    window.frames.project.postMessage({ type: 'getLocation' }, '*');
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [section]);

  const renderIframe = useMemo(
    () =>
      section ? (
        <iframe
          height="100%"
          name="project"
          onLoad={onLoadIframe}
          src={decodeURIComponent(currentProjectUrl)}
          width="100%"
        />
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section]
  );

  return (
    <div className={classnames(styles.container, className)}>
      {isLoading && (
        <div className={styles.veil}>
          <Spinner />
        </div>
      )}
      {renderIframe}
    </div>
  );
};

Project.propTypes = {
  className: PropTypes.string,
};

Project.defaultProps = {
  className: undefined,
};

export default memo(Project);
