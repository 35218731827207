class Auth {
  me = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_AUTH_PUBLIC_URL}/api/v1/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        source: 'backoffice',
      },
      method: 'GET',
    });

    return response.json();
  };

  permissions = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_AUTH_PUBLIC_URL}/api/v1/permissions`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        source: 'backoffice',
      },
      method: 'GET',
    });

    return response.json();
  };
}

export default new Auth();
