import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import UserContext from 'context/user';
import { LOGIN_PATH } from './paths';

const PrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(UserContext);
  const isAuth = !!user;

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_PATH,
              search: window.location.search,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
