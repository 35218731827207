import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AuthCallback from 'pages/AuthCallback';
import { StoreProvider } from 'context/store';
import { UserProvider } from 'context/user';
import Login from 'pages/Login';
import Home from 'pages/Home';
import { AUTH_CALLBACK_PATH, HOME_PATH, LOGIN_PATH } from './paths';
import PrivateRoute from './PrivateRoute';

const Router = () => (
  <BrowserRouter>
    <UserProvider>
      <Switch>
        <Route exact path={LOGIN_PATH}>
          <Login />
        </Route>
        <Route exact path={AUTH_CALLBACK_PATH}>
          <AuthCallback />
        </Route>
        <PrivateRoute path={HOME_PATH}>
          <StoreProvider>
            <Home />
          </StoreProvider>
        </PrivateRoute>
      </Switch>
    </UserProvider>
  </BrowserRouter>
);

export default Router;
