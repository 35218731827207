import React, { useState } from 'react';
import PropTypes from 'prop-types';
import auth from 'services/auth';

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  const fetchUser = async (token) => {
    const responseMe = await auth.me(token);
    const responsePermissions = await auth.permissions(token);

    setUser({ ...responseMe.data.user, permissions: responsePermissions.data.permissions });
  };

  return (
    <UserContext.Provider
      value={{
        fetchUser,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContext;
